@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400&display=swap');

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	z-index: 100;
}

.my-float {
	margin-top: 16px;
}

.made-by {
	font-family: 'Inconsolata', monospace;
}

.hero {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../public/hero.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.g-recaptcha {
	transform: scale(0.98);
	-webkit-transform: scale(0.98);
	transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}

.balao {
	background: #1b1b1b;
	position: fixed;
	width: 240px;
	height: 60px;
	bottom: 40px;
	right: 120px;
	font-size: 16px;
	border-radius: 10px;
	border-width: 1px;
	background-color: #d8ddd9;
	color: rgb(46, 45, 45);
	
	text-align: center;
	z-index: 100;
}
.balao:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-left-color: #d8ddd9;
	border-right: 0;
	margin-top: -15px;
	margin-right: -15px;
}